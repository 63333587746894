import React, { useState, useEffect } from "react";
import "../styles/commonUI/feedbackButton.css";
import axios from "axios";
import { toast } from "react-toastify";
import qs from "qs";

export default function FloatingContactForm() {
  const [isOpen, setIsOpen] = useState(false);
  const [isHomePage, setIsHomePage] = useState(true);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    query: "",
  });
  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    const checkIfHomePage = () => {
      const isHome = window.location.pathname === "/,#";
      setIsHomePage(isHome);
    };

    checkIfHomePage();
    window.addEventListener("popstate", checkIfHomePage);

    return () => {
      window.removeEventListener("popstate", checkIfHomePage);
    };
  }, []);

  // const handleInputChange = (e: React.ChangeEvent <HTMLInputElement | HTMLTextAreaElement> ) => {
  //   const { name, value } = e.target;
  //   setFormData(prevData => ({
  //     ...prevData,
  //     [name]: value
  //   }));
  // };

  //   const handleSubmit = (e: React.FormEvent) => {
  //       e.preventDefault();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    if (
      formData.name === "" ||
      formData.phone === "" ||
      formData.email === "" ||
      formData.query === ""
    ) {
      toast.error("Please fill all required fields");
      return;
    }
    e.preventDefault();
    try {
      const response = await axios.post(
        "/contact.php",
        qs.stringify({
          name: formData.name,
          email: formData.email,
          number: formData.phone,
          message: formData.query,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );

      if (response.data.success) {
        toast.success("Message sent succesfully");
      } else {
        toast.error(response.data.error || "Something went wrong.");
      }
      toast.success("Message sent succesfully");
    } catch (error) {
      toast.error("Failed to send message. Please try again later.");
    }

    setFormData({ name: "", email: "", phone: "", query: "" });
    setIsSubmitted(true);
    setTimeout(() => {
      setIsSubmitted(false);
      setIsOpen(false);
    }, 3000);
  };

  if (isHomePage) return null;

  return (
    <div className={`floating-contact-form ${isOpen ? "open" : ""}`}>
      {!isOpen && (
        <button className="contact-icon" onClick={() => setIsOpen(true)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path>
          </svg>
        </button>
      )}

      {isOpen && (
        <div className="contact-form">
          <div>
            <h4> Let's Chat</h4>
          </div>
          <button className="close-btn" onClick={() => setIsOpen(false)}>
            ×
          </button>
          {!isSubmitted ? (
            <form onSubmit={handleSubmit}>
              <input
                type="text"
                name="name"
                placeholder="Your Name"
                value={formData.name}
                onChange={handleInputChange}
                required
              />
              <input
                type="email"
                name="email"
                placeholder="Your Email"
                value={formData.email}
                onChange={handleInputChange}
                required
              />
              <input
                type="tel"
                name="phone"
                placeholder="Your Phone Number"
                value={formData.phone}
                onChange={handleInputChange}
                required
              />
              <textarea
                name="query"
                placeholder="Your Query"
                value={formData.query}
                onChange={handleInputChange}
                required
              ></textarea>
              <button type="submit">Send Query</button>
            </form>
          ) : (
            <div className="thank-you-message">We're happy to assist you!</div>
          )}
        </div>
      )}
    </div>
  );
}
